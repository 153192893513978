<template>
  <div id="home">
    <div class="home-banner" id="banner">
      <div class="container">
        <div class="logo-wrapper">
        <div class="slogan-title">
          {{$t('homeSlogan')}}
        </div>
        <div class="slogan-subtitle">
          {{$t('homeDescription')}}
        </div>
        <div class="region">
          <a href="https://tap.ins.to/us" target="_blank">
            <div class="region-us">INSTO Tap US</div>
          </a>
          <a href="https://tap.ins.to" target="_blank">
            <div class="region-tw">INSTO Tap TW</div>
          </a>
        </div>
        <div class="downloads">
          <div class="app-logo bg-contain"></div>
          <div class="ios-logo bg-contain"></div>
          <div class="android-logo bg-contain"></div>
      </div>
      </div>
      <div class="phone-img bg-contain"></div>
      </div>
    </div>
    <div id="services">
      <!-- insto-tap us-->
      <a href="https://tap.ins.to/us" target="_blank">
        <div class="card card-insto-tap">
          <div class="service-info">
            <img src="../assets/imgs/home/insto-tap-logo-us.svg" class="service-logo">
            <div class="service-tag">
              {{$t('homeServiceTagTapUs')}}
              </div>
            <div class="service-text">
              {{$t('homeServiceTextTapUs')}}
            </div>
            <div class="learn-more-btn">
                {{$t('homeLearnMore')}}
              <div class="learn-more-arrow">
                <img src="../assets/imgs/home/learn-more-arrow.svg">
              </div>
            </div>
          </div>
          <div class="image-container insto-tap-us">
          </div>
        </div>
      </a>
            <!-- insto-tap -->
            <a href="https://tap.ins.to" target="_blank">
        <div class="card card-insto-tap">
          <div class="service-info">
            <img src="../assets/imgs/home/insto-tap-logo-tw.svg" class="service-logo">
            <div class="service-tag">
              {{$t('homeServiceTagTap')}}
              </div>
            <div class="service-text">
            {{$t('homeServiceTextTap')}}
            </div>
            <div class="learn-more-btn">
                {{$t('homeLearnMore')}}
              <div class="learn-more-arrow">
                <img src="../assets/imgs/home/learn-more-arrow.svg">
              </div>
            </div>
          </div>
          <div class="image-container insto-tap">
          </div>
        </div>
      </a>
      <!-- api -->
      <router-link to="/insto-api">
      <div class="card card-insto-api">
        <div class="service-info">
          <img src="../assets/imgs/home/insto-api-logo.svg" class="service-logo">
           <div class="service-tag">
            {{$t('homeServiceTagApi')}}
            </div>
          <div class="service-text">
           {{$t('homeServiceTextApi')}}
          </div>
          <div class="learn-more-btn">
                {{$t('homeLearnMore')}}
              <div class="learn-more-arrow">
                <img src="../assets/imgs/home/learn-more-arrow.svg">
              </div>
            </div>
        </div>
        <div class="image-container insto-api">
        </div>
      </div>
    </router-link>
      <!-- credit -->
      <router-link to="/insto-credit">
      <div class="card card-insto-credit">
        <div class="service-info">
          <img src="../assets/imgs/home/insto-credit-logo.svg" class="service-logo">
          <div class="service-tag">
             {{$t('homeServiceTagCredit')}}
          </div>
          <div class="service-text">
            {{$t('homeServiceTextCredit')}}
          </div>
          <div class="learn-more-btn">
                {{$t('homeLearnMore')}}
              <div class="learn-more-arrow">
                <img src="../assets/imgs/home/learn-more-arrow.svg">
              </div>
            </div>
        </div>
        <div class="image-container insto-credit">
          </div>
      </div>
    </router-link>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Home',
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#home {
  overflow: hidden;
  .home-banner {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    margin-top: 72px;
    background-color: rgb(244, 252, 254);
    background-image: url('../assets/imgs/home/bg-1920.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    @media screen and (max-width: 1024px) {
        background-size: contain;
        background-position: center center;
        height: 100%;
    }
    .container {
      padding: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 1000px) {
        padding: 30px;
      }
      @media screen and (max-width: 768px) {
        padding: 0px;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }
      .logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        @media screen and (min-width: 1200px) {
          max-width: 480px;
        }
        @media screen and (max-width: 768px) {
          margin-top: 30px;
          align-items: center;
          padding: 0 20px;
          width: 100%;
          text-align: center;
        }
        @media screen and (max-width: 500px) {
          margin-top: 12px;
          align-items: center;
          padding: 0 20px;
          width: 100%;
        }
      .slogan-title {
        font-size: 30px;
        @media screen and (max-width: 500px) {
          font-size: 24px;
        }
      }
      .slogan-subtitle {
        margin-top: 12px;
        font-size: 19px;
        line-height: 1.3;
        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
    }
  }
 #services {
    padding: 50px 150px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width: 1512px) {
      padding: 50px 40px;
    }
    @media screen and (max-width: 900px) {
      flex-direction: column;
      padding: 40px;
    }
    @media screen and (max-width: 1024px) {
      padding: 20px;
    }

    a {
      cursor: pointer;
      width: 50%;
      max-width: 500px;
      &:hover {
        transform: translateY(6px);
      }
      @media screen and (max-width: 900px) {
        margin: 0 auto;
        width: 80%;
      } 
      @media screen and (max-width: 768px) {
        width: 100%;
      } 
    }
      .card {
        background-color: #069CF1;
         display: flex;
         justify-content: center;
         align-items: center;
         flex-direction: column;
         margin: 6px;
         border-radius: 25px;
         overflow: hidden;
         box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.12);
         @media screen and (max-width: 900px) {
          margin-bottom: 10px;
        } 
         &:nth-of-type(even){
           background-color: #006CDC;
         }
      }

      .service-info {
        padding: 40px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: white;
        @media screen and (max-width: 1512px) {
          padding: 25px 30px;
        } 
        @media screen and (max-width: 650px) {
          padding: 25px 20px;
          width: 100%;
        }
      } 

      .service-tag {
        padding: 6px 12px;
        border: 1px solid white;
        border-radius: 50px;
        font-size: 13px;
        margin-bottom: 20px;
      }

      .service-logo {
        height: 30px;
        margin-bottom: 20px;
      }
      .service-text {
        line-height: 22px;
        height: 80px;
        @media screen and (max-width: 900px) {
          height: auto;
        }
      }

      .learn-more-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 40px;
      }

      .learn-more-arrow {
        height: 30px;
        width: 30px;
        margin-left: 6px;
        border-radius: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .image-container {
          width: 100%;
          height: 200px;
          background-size: cover;
          @media screen and (max-width: 900px) {
            height: 120px;
          }
          @media screen and (max-width: 768px) {
            height: 100px;
          }
        }

      .credit-img-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0,0, 0.45);
        color: white;
        font-size: 30px;
        line-height: 460px;
        text-align: center;
        display: none;
        z-index: 1;
      }

      .insto-tap {
        background-image: url('../assets/imgs/home/insto-tap.png');
      }
      .insto-api {
        background-image: url('../assets/imgs/home/insto-api.png');
      }
      .insto-credit {
        background-image: url('../assets/imgs/home/insto-credit.png');
      }
      .insto-tap-us {
        background-image: url('../assets/imgs/home/insto-tap-us.jpg');
      }
    }
  }


  .downloads {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 30px 0;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  .app-logo {
    height: 39px;
    width: 39px;
    border-radius: 11px;
    box-shadow: 0 3px 6px rgba(13, 138, 273, 0.2);
    margin-right: 12px;
    background-image: url("../assets/imgs/app-logo.png");
    @media screen and (max-width: 320px) {
      margin-right: 12px;
      margin-bottom: 0;
      height: 30px;
      width: 30px;
    }
  }

  .android-logo {
    height: 39px;
    width: 126px;
    background-image: url("../assets/imgs/download-google-play.png");
    @media screen and (max-width: 320px) {
      height: 30px;
      width: 97px;
    }
  }
}

.ios-logo {
  height: 39px;
  width: 126px;
  margin-right: 12px;
  background-image: url("../assets/imgs/download-app-store.png");
  @media screen and (max-width: 320px) {
    margin-right: 12px;
    margin-bottom: 0;
    height: 30px;
    width: 97px;
  }
}

.bg-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-contain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}


.phone-img {
  width: 500px;
  height: 440px;
  flex-shrink: 0;
  background-image: url('../assets/imgs/home/app-us.png');
  @media screen and (max-width: 950px) {
    width: 360px;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    height: 360px;
  }
}

.region {
  color: white;
  font-size: 16px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    color: white;
    transition: all .3s ease-in-out;
    &:hover {
      color: white;
      transform: translateY(6px);
    }  
  }

  .region-us {
    padding: 12px 10px;
    background-color: #00c8ff;
    margin-right: 8px;
    width: 150px;
    border-radius: 55px;
    text-align: center;
    &:hover {
      background-color: #006CDC;
    }
  }
  .region-tw {
    padding: 12px 10px;
    background-color: #00c8ff;
    width: 150px;
    border-radius: 55px;
    text-align: center;
    &:hover {
      background-color: #069CF1;
    }
  }
}




</style>
