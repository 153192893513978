<template>
  <div id="api-service">
    <transition name="modal">
    <div id="download-modal" class="download-modal" v-show="isShow">
      <div class="modal-header">
        <div class="modal-title">
          {{$t('apiModalTitle')}}
      </div>
       <div class="modal-closed" v-on:click="isShow=!isShow"/>
      </div>
      <div class="modal-subtitle">
        {{$t('apiModalApplySeller')}}
      </div>
      <div class="modal-text">
        {{$t('apiModaltext_apply')}}
      </div>
      <div class="download-btns">
          <img src="../assets/imgs/onelink.png" alt="download qrcode" class="qrcode">
         <a
          href="https://apps.apple.com/tw/app/id1525691104"
          target="_blank"
          rel="noopener"
          >
        <img src="../assets/imgs/download-app-store.png" alt="ios app download">
         </a>
          <a
          href="https://play.google.com/store/apps/details?id=com.installments.instotap"
          target="_blank"
          rel="noopener"
        >
        <img src="../assets/imgs/download-google-play.png" alt="android app download">
        </a>
      </div>
       <div class="modal-subtitle">
         {{$t('apiModalSeller')}}
      </div>
      <div class="modal-text">
         {{$t('apiModaltext_download_login')}}
         <a href="https://merchant.ins.to/" target="blank">
          {{$t('apiModaltext_download_url')}}
         </a>
         {{$t('apiModaltext_download')}}<router-link to="/contact-us">{{$t('apiContact')}}</router-link>
      </div>
    </div>
  </transition>
    <BaseTitle pageTitle="API" :pageSubtitle="$t('apiPagesubTitle')"></BaseTitle>
    <div class="api-service-wrapper">
      <div class="service-container">
         <div class="service-title">{{$t('apiTitle')}}</div>
          <div class="service-text">{{$t('apiText')}}</div>
          <div class="service-ver">
            {{$t('apiType')}}
            </div>
          <div class="service-file" v-on:click="isShow= !isShow">
            {{$t('apiGet')}}
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";

export default {
    metaInfo: {
      title: 'Home',
    },
  data() {
    return {
      isShow: false
    };
  },
  components: {
    BaseTitle
  }
};
</script>

<style
    DownloadModal lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#api-service {
  position: relative;
  .api-service-wrapper {
    padding: 50px 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .service-container {
    width: 320px;
    &:first-child {
      margin-right: 50px;
    }
    .service-title {
      font-size: 18px;
      margin-bottom: 16px;
    }
    .service-text {
      line-height: 26px;
    }
    .service-ver {
      color: $subTitle;
      margin: 16px 0;
    }
    .service-file {
      cursor: pointer;
      color: $mainBlue;
      transition: .3s ease-in-out;
      margin-bottom: 12px;
      &:hover {
      color: $deepBlue;
      }
    }
  }
  .service-notice {
     color: #30bf96;
     font-size: 13px;
  }
}

/deep/ {
  .about-tabs {
    display: none;
  }
}

#download-modal{
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
  background: white;
  z-index: 997;
  border-radius: 12px;
  box-shadow: 0 5px 10px $shadowBlue;
}

.download-modal {
  .modal-header {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .modal-text {
    line-height: 22px;
    margin-bottom: 16px;
  }

  .modal-closed {
    cursor: pointer;
    height: 16px;
    width: 16px;
    background-image:url("../assets/imgs/close.svg") ;
    background-size: cover;
  }

  .modal-subtitle {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    color: $textBlue;
  }

  .download-btns {
    display: flex;
    align-items: flex-end;
     margin: 20px 0;
     padding-bottom: 20px;
     border-bottom: 1px solid $lightBlue;
     .qrcode {
        height: 80px;
      }
    img {
      height: 36px;
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}

.modal-enter-active {
  transform: translateY(-50px);
  transition: all .3s ease;
}

.modal-leave-active {
 transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

a {
  color: $mainBlue;
  &:hover {
    color: $deepBlue;
  }
}

/deep/ {
  #base-title {
    .top-banner {
    .banner-photo {
      background-image: url('../assets/imgs/page-banner/api.jpg');
      }
    }
  }
}


</style>
