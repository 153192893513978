<template>
  <footer v-show="$route.query.app !== '1' && $route.path !== '/insto-flexi'" :class="{ 'is-footer': footer }">
    <div class="footer-left">
      <img src="../assets/imgs/logo.svg" height="30" alt="logo">
    </div>
    <div class="footer-right">
      <div class="footer-right-section">
        <div class="link-section">
          <div class="link-title">Service</div>
          <a
            ref="nooper"
            href="https://tap.ins.to/us"
            class="link"
            target="_blank"
            >INSTO Tap US</a
          >
          <a
            ref="nooper"
            href="https://tap.ins.to"
            class="link"
            target="_blank"
            >INSTO Tap TW</a
          >
          <router-link to="/insto-api" class="link">
          INSTO API
          </router-link>
          <router-link to="/insto-credit" class="link"
            >INSTO Credit
          </router-link>
        </div>
        <div class="link-section">
          <div class="link-title">About Us</div>
          <router-link to="/about-us" class="link">
            {{$t('footerAboutCompany')}}
          </router-link>
          <router-link to="/our-history" class="link">
            {{$t('footerAboutHistory')}}
          </router-link>
          <router-link to="/press" class="link">
            {{$t('footerAboutPress')}}
          </router-link>
        </div>
        <div class="link-section">
          <div class="link-title">Follow us</div>
          <router-link to="/contact-us" class="link">
          {{$t('footerfollowContact')}}</router-link>
          <a
            ref="nooper"
            href="https://support.ins.to/hc/zh-tw"
            class="link"
            target="_blank"
            >
            {{$t('footerfollowfaq')}}
          </a>
          <a
            ref="nooper"
            href="https://www.facebook.com/INSTO.Taiwan"
            class="link"
            target="_blank"
            >
            Facebook
          </a>
        </div>
        </div>
        <div class="footer-right-section">
          <div class="link-section partner">
          <div class="link-title">PartnerShip</div>
          <div class="link-images">
            <a
              ref="nooper"
              href="https://ebank.taipeifubon.com.tw/B2C/common/Index.faces"
              target="_blank"
              class="partner-link"
              >
              <img src="../assets/imgs/footer/fubon.svg">
            </a>
            <a
              ref="nooper"
              href="https://www.elavon.com/"
              target="_blank"
              class="partner-link"
              >
              <img src="../assets/imgs/footer/usbank-elavon.svg" style="height: 25px;">
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="custom-select">
        <select class="lan-btns" v-model="language">
          <option v-for="(item, index) in lang" :value="item.value" :key="index">
            {{ $t(`${item.value}`) }}
          </option>
        </select>
      </div> -->
      <!-- <div class="terms">
        <a href="/terms/member.html" class="term" target="_blank">
         {{$t('footerTermsService')}}
        </a>
        <a href="/terms/privacy.html" class="term" target="_blank">
         {{$t('footerTermsPrivacy')}}
        </a> 
        <a
        ref="nooper"
        href="https://deprecate.ins.to/app/signin"
        target="_blank"
        class="orgin-login"
      >
      {{$t('footerOrginLogin')}}
      </a>
      </div> -->
      <h3>copyright © 2020 installments,Inc.</h3>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      language: this.$i18n.locale,
      lang: [
        {
        value: 'zh'
        }, 
        {
        value: 'en'
        }
      ],
    }
  },
  watch: {
    '$route' () {
      this.toggle = false
    },
    language(val) {
        this.$i18n.locale = val
        console.log(val)
    }
  },
  computed: {
    footer(){
      if(this.$route.name == "home") {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

footer {
  padding: 60px 50px;
  background: $footer;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 900px) {
    padding: 50px;
  }
  @media screen and (max-width: 680px) {
    flex-direction: column;
    align-items: center;
    padding: 30px;
    .footer-left {
      display: none;
    }
  }
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  margin-right: 100px;
  @media screen and (max-width: 680px) {
    justify-content: flex-start;
    margin-right: 0;
  }
}


.footer-right {
  @media screen and (max-width: 680px) {
    display: flex;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.footer-right-section {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
}

.link-section {
  display: flex;
  flex-direction: column;
  margin: 0 80px 50px 0;
  width: 100px;
  &:last-child {
    margin-right:0;
  }
  @media screen and (max-width: 500px) {
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-bottom: 50px;
  }
  .link-title {
    font-weight: bold;
    font-size: 12px;
    color: #A6C8D1;
    text-transform: uppercase;
  }
  .link {
    margin-top: 12px;
    font-size: 13px;
    color: $subTitle;
  }
  a:hover {
    color: $mainBlue;
  }
}

h3 {
  margin: 10px 0 0 0 ;
  font-size: 13px;
  font-weight: normal;
  color: $subTitle;
  @media screen and (max-width: 500px) {
      text-align: center;
    }
}

.partner {
  width: auto;
  .link-title {
    margin-bottom: 12px;
  }
}

.orgin-login {
  text-decoration: underline;
  font-size: 13px;
  color: $subTitle;
}

.link-images {
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 500px) {
      flex-direction: column;
    }
  img {
    height: 18px;
  }
  .partner-link {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
    @media screen and (max-width: 500px) {
      margin-right: 0px;
      margin-top: 20px;
    }
  }
}

.lan-btns {
  width: 100px;
  background: #d3ecf3;
}

select{
  color: $textBlack;
  font-size: 13px;
  font-family: 'lato';
  padding: 6px 12px;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../assets/imgs/arrow.svg') 90% / 12% no-repeat #d3ecf3 !important;
}

select::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

@media screen and (min-width:0\0) {
    select {
        background: none\9;
        padding: 5px\9;
    }
}

.terms {
  margin-top: 8px;
  margin-bottom: 12px;
  .term {
    margin-right: 12px;
    font-size: 13px;
    color: $subTitle;
    &:hover {
    color: $mainBlue;
    }
  }
}

.is-footer {
  // margin-top: 1000px;
  // @media screen and (max-width: 1024px) {
  //   margin-top: 1000px;
  // }
  // @media screen and (max-width: 900px) {
  //   margin-top: 1100px;
  // }
  // @media screen and (max-width: 768px) {
  //   margin-top: 1050px;
  // }
  // @media screen and (max-width: 500px) {
  //   margin-top: 1100px;
  // }
}
</style>
