import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home'
import ContactUs from './views/ContactUs'
import AboutUs from './views/AboutUs'
import OurHistory from './views/OurHistory'
import Press from './views/Press'
import InstoApi from './views/InstoApi'
import InstoCredit from './views/InstoCredit'
import PageNotFound from './views/PageNotFound'
import VueCarousel from 'vue-carousel'
import VueMeta from 'vue-meta'


Vue.use(VueCarousel);
Vue.use(Router)
Vue.use(VueMeta)

export default new Router({
   mode: 'history',
   linkActiveClass: "active",
  scrollBehavior: (to, from, savedPosition) => {
    console.log(to, from);
    return savedPosition || {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      path: '/',
      component: Home,
      name: 'home'
    },
     {
      path: '/insto-api',
      component: InstoApi
    },
     {
      path: '/insto-credit',
      component: InstoCredit
    },
    //  {
    //   path: '/insto-flexi',
    //   component: InstoFlexi
    // },
    {
      path: '/contact-us',
      component: ContactUs
    },
    {
      path: '/about-us',
      component: AboutUs
    },
    {
      path: '/our-history',
      component: OurHistory
    },
    {
      path: '/press',
      component: Press
    },
    { 
      path: "*",
      component: PageNotFound 
    }
  ],
})
