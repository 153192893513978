<template>
  <div id="contact-us">
    <BaseTitle pageTitle="Contact Us" :pageSubtitle="$t('contactPagesubTitle')">
    </BaseTitle>
    <div class="contact-us-wrapper">
      <div class="form-wrapper">
        <div class="form-content">
          <p>
            {{$t('contactDescription')}}
          </p>
          <p>
            {{$t('contactSubdescription')}}
          </p>
        </div>
        <form id="contact-us-form">
          <div class="input-wrapper">
            <label>
              <span>*</span>
              {{$t('contactFormName')}}
            </label>
            <input id="name" type="text" :placeholder="$t('contactFormNamePlaceholder')" required name="name" />
          </div>
          <div class="input-wrapper">
            <label><span>*</span>{{$t('contactFormEmail')}}</label>
            <input id="email" type="email" :placeholder="$t('contactFormEmailPlaceholder')" required name="email" />
          </div>
          <div class="input-wrapper">
            <label><span>*</span>{{$t('contactFormType')}}</label>
            <div class="task-select">
              <select id="task-option" name="task-option">
                <option value="comments">{{$t('contactFormType1')}}</option>
                <option value="partnership">{{$t('contactFormType2')}}</option>
                <option value="press">{{$t('contactFormType3')}}</option>
                <option value="others">{{$t('contactFormType4')}}</option>
              </select>
            </div>
          </div>
          <div class="input-wrapper">
            <label><span>*</span>{{$t('contactFormTitle')}}</label>
            <input id="title" type="text" :placeholder="$t('contactFormTitlePlaceholder')" required name="title" />
          </div>
          <div class="input-wrapper">
            <label><span>*</span>{{$t('contactFormText')}}</label>
            <textarea id="content" style="overflow:auto; resize: none" :placeholder="$t('contactFormTextPlaceholder')"
              required name="content" />
            <input id="submit" class="submit" type="submit" name="submit" :value="$t('contactFormSubmit')" />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";
import $ from "jquery";

export default {
    metaInfo: {
      title: 'Home',
    },
  components: {
    BaseTitle
  },
  mounted: function() {
    let __busy = 0;
    $("#contact-us-form").submit(function(e) {
      e.preventDefault();
      if (__busy) return;
      let jdata = {
        request: {
          requester: { name: "name", email: "email" },
          subject: "subject",
          comment: { body: "test" }
        }
      };
      jdata.request.requester.name = $("#name").val();
      jdata.request.requester.email = $("#email").val();
      jdata.request.subject = "[Tap Contact Us] " + $("#title").val();
      jdata.request.comment.body = $("#content").val();
      jdata = JSON.stringify(jdata);
      __busy = 1;
      $.ajax({
        url: "https://insto.zendesk.com/api/v2/requests.json",
        datatype: "json",
        data: jdata,
        type: "POST",
        async: true,
        processData: true,
        contentType: "application/json; charset=UTF-8"
      })
        .done(function(response, status, jqXHR) {
          alert("謝謝您的留言，我們會盡快回覆您。");
          $("#contact-us-form")[0].reset();
        })
        .fail(function(jqXHR, status, errtxt) {
          alert("Ajax request error : " + errtxt);
        })
        .always(function(jqXHR) {
          __busy = 0;
        });
    });
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";


/deep/ {
  .about-tabs {
    display: none;
  }
}
.top-banner {
   position: relative;
   margin-top: 30px;
  .banner-photo {
    width: 80%;
    height: 270px;
    background-image: url('../assets/imgs/page-banner/contact.png');
    background-size: cover;
    background-position: center;
  }
  .banner-deco {
    position: absolute;
    top: -30px;
    z-index: -1;
    height: 270px;
    width: 60%;
    background: $lightBlue;
  }
}

.contact-us-wrapper {
  padding: 0px 60px 50px 60px;
  @media screen and (max-width: 900px) {
    padding: 50px;
  }
  @media screen and (max-width: 720px) {
    padding: 50px 30px;
  }
}

.form-wrapper {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 720px) {
    margin-top: 0;
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 500px) {
    // padding: 0 30px;
  }
  .form-content {
    width: 340px;
    margin-right: 50px;
    font-size: 14px;
    p {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 1024px) {
      margin-right: 50px;
    }
    @media screen and (max-width: 720px) {
      margin-right: 0;
      width: 60%;
      margin-top: 0;
    }
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
}

#contact-us-form {
  min-width: 300px;
  label {
    font-size: 14px;
  }
  @media screen and (max-width: 720px) {
    width: 60%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.input-wrapper {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }
  span {
    color: #CE1F1F;
  }
}

label {
  display: block;
  font-size: 14px;
}

input {
  color: $textBlack;
  width: 100%;
  border: none;
  border-bottom: 1px solid $bgLightGrey;
  padding: 12px 0;
  outline: none;
  font-size: 14px;
  margin-right: 8px;
  transition: 0.3s ease-in-out;
}

input:last-child {
  margin-right: 0;
}

input::placeholder {
  color: $placeholderGrey;
  font-size: 14px;
}

input:focus {
  border-bottom: 1px solid $mainBlue;
}

.submit {
  background-color: $mainBlue;
  &:hover {
    transform: translateY(4px);
    box-shadow: 0 2px 10px rgba(47, 203, 255, 0.47);
    background-color: $textBtnBlue;
  }
}

input[type="submit"] {
  margin: 60px auto 0 auto;
  border: 0;
  display: block;
  cursor: pointer;
  color: $white;
  width: 100%;
  height: 36px;
  line-height: 36px;
  padding: 0;
  border-radius: 100px;
  box-shadow: 0 2px 6px rgba(47, 203, 255, 0.47);
  background-color: $mainBlue;
  transition: 0.3s ease-in-out;
}
@media screen and (max-width: 320px) {
  input[type="submit"] {
    margin: 50px auto 0 auto;
    width: 100%;
  }
}

a {
  color: $mainBlue;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

a:hover {
  color: $textBtnBlue;
}

textarea {
  font-family: 'lato';
  width: 100%;
  height: 80px;
  overflow-y: visible;
  margin-top: 12px;
  border: 1px solid $bgLightGrey;
  outline: none;
  padding: 12px;
  font-size: 14px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  background: $white;
  border-radius: 4px;
    &:focus {
    border-bottom: 1px solid #00c8ff;
  }
}

textarea::placeholder {
  color: $placeholderGrey;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

.task-select {
  margin-top: 12px;
}

select{
  width: 100%;
  color: $textBlack;
  font-size: 14px;
  font-family: 'lato';
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 16px;
  border: 1px solid $bgLightGrey;
  border-top: none;
  border-right: none;
  border-left: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../assets/imgs/arrow.svg') 98% / 4% no-repeat !important;
  &:focus {
    border-bottom: 1px solid #00c8ff;
  }
}

select::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

@media screen and (min-width:0\0) {
    select {
        background: none\9;
        padding: 5px\9;
    }
}

/deep/ {
  #base-title {
    .top-banner {
    .banner-photo {
      background-image: url('../assets/imgs/page-banner/contact.png');
      }
    }
  }
}

</style>
