<template>
  <div id="app">
    <transition name="fade">
      <CookieAlert></CookieAlert>
    </transition>
    <BaseHeader />
    <transition name="fade">
    <router-view class="router-view-layout"/>
    </transition>
    <BaseFooter />
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader'
import BaseFooter from "@/components/BaseFooter";
import CookieAlert from '@/components/CookieAlert'

export default {
  name: 'App',
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: null,
    // all titles will be injected into this template
    titleTemplate: '%s | INSTO Official',
  },
  components: {
    BaseHeader,
    BaseFooter,
    CookieAlert
  }
}
</script>

<style lang="scss">
@import '../src/assets/sass/main.scss';

#app {
  position: relative;
}

.router-view-layout{
  overflow: hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

</style>
