import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import '@/assets/sass/main.scss'
import VueCookie from 'vue-cookie'
import VueI18n from 'vue-i18n' 
import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

Vue.config.productionTip = false
Vue.use(VueCookie)
Vue.use(VueI18n)

var VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)

const i18n = new VueI18n({
   locale: 'en', 
   messages: {
     'zh': require('./components/lang/zh'),   // 中文语言包
     'en': require('./components/lang/en'),   // 英文语言包
     'es': require('./components/lang/es')    // 西班牙文语言包
   }
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

