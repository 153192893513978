<template>
  <div id="press-list">
    <div class="list-wrapper" v-for="(news, index) in list"
    :key="index">
       <div class="press-name">{{news.name}}</div>
       <a :href="news.url" target="_blank" ref="nooper"> 
        <div class="press-title">{{news.title}}</div>
       </a>
      <div class="press-date">{{news.date}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: { 
    list: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#press-list{
  .list-wrapper {
    display: inline-block;
    width: 210px;
    margin: 0 50px 50px 50px;
    @media screen and (max-width: 720px) {
      width: 40%;
      margin: 0 20px 30px 20px;
    }
    @media screen and (max-width: 600px) {
      width: 40%;
       margin: 0 15px 30px 15px;
    }
    @media screen and (max-width: 520px) {
      width: 100%;
      margin: 0 0 30px 0;
    }
    .press-name, .press-date {
      font-size: 13px;
      color: $subTitle;
    }
    .press-name {
      margin-bottom: 14px;
    }
    .press-date {
      margin-top: 14px;
    }
    .press-title {
      font-size: 14px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
  
}
</style>

