<template>
  <div id="base-title">
    <div class="page-title-wrapper">
      <div class="page-title">{{pageTitle}}</div>
      <div class="page-subtitle">{{pageSubtitle}}</div>
    </div>
    <div class="about-tabs">
        <router-link 
        v-for="(tab, index) in tabs"
        :key="index"
        :to="tab.link"
        class="tab">
        {{ tab.title }}
        </router-link>
    </div>
    <div class="top-banner">
      <div class="banner-deco" />
      <div class="banner-photo"/>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      tabs: [
        { 
          index: '0',
          title: this.$t('titleAbout'),
          link: '/about-us'
        },
        { 
          index: '1',
          title: this.$t('titleHistory'),
          link: '/our-history'
        },
        { 
          index: '2',
          title: this.$t('titlePress'),
          link: '/press'
        },
      ]
    }
  },
  props: { 
    pageTitle: {
      type: String,
      default: ''
    },
    pageSubtitle: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";
#base-title{
  cursor: default;
  .page-title-wrapper {
    margin-top: 140px;
    margin-right: 60px;
    text-align: right;
    display: block;
    @media screen and (max-width: 900px) {
      margin-top: 100px;
    }
    @media screen and (max-width: 600px) {
       margin-right: 20px;
       margin-bottom: 50px;
    }
    .page-title {
      color: $mainBlue;
      font-size: 44px;
      margin-bottom: 8px;
      letter-spacing: 2.4px;
      @media screen and (max-width: 800px) {
        margin-bottom: 50px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 20px;
        font-size: 30px;
      }
    }
    .page-subtitle {
      font-size: 16px;
      color: $textBlack;
       @media screen and (max-width: 600px) {
        font-size: 14px;
      }
    }
  }
  .about-tabs {
    margin-left: 60px;
    margin-bottom: 30px;
     @media screen and (max-width: 600px) {
        margin-left: 20px;
      }
  .tab {
    margin-right: 50px;
     @media screen and (max-width: 600px) {
        margin-right: 30px;
      }
    &:active {
      color: $mainBlue;
    }
  }
}
    .top-banner {
    position: relative;
    margin-top: 60px;
    .banner-photo {
      max-width: 1140px;
      width: 86%;
      height: 300px;
      background-image: url('../assets/imgs/page-banner/about.jpg');
      background-size: cover;
      background-position: center;
       @media screen and (max-width: 600px) {
        width: 100%;
        height: 220px;
      }
      
    }
    .banner-deco {
      position: absolute;
      top: -30px;
      z-index: -1;
      height: 270px;
      width: 60%;
      background: $lightBlue;
      animation-name: blue-bar;
      animation-duration: 2s;
      animation-delay: .2s;
       @media screen and (max-width: 600px) {
       width: 80%;
        height: 220px;
      }
    }
    @keyframes blue-bar {
      0% {
        width: 20%;
      }
      20% {
        width: 0%;
      }
      21% {
        width: 0%;
      }
      100% {
        width: 60%;
      }
    }
  }
}

</style>
