<template>
  <div id="credit-service">
    <BaseTitle pageTitle="INSTO Credit" :pageSubtitle="$t('creditPagesubTitle')"></BaseTitle>
    <div class="api-service-wrapper">
      <div class="service-container">
         <div class="service-title"> {{$t('creditSellerTitle')}}</div>
          <div class="service-text">{{$t('creditSellerText')}}
          </div>
          <div class="service-notice">
            <span>
              {{ $t('creditSellerNotice') }}
            </span>
            <!-- <a href="asset/file/INSTO_Credit_FAQ.pdf" download="insto-credit-faq">
             {{$t('creditSellerFaq')}}
            </a> -->
          </div>
      </div>
      <div class="service-container">
         <div class="service-title">
            {{$t('creditBuyerTitle')}}
         </div>
          <div class="service-text">
            {{$t('creditBuyerText')}}
          </div>
          <!-- <div class="service-notice">
            {{$t('creditBuyerReviewNotice1')}}
            <span>
            {{$t('creditBuyerReviewNotice2')}}
            </span>
            <br>
            {{$t('creditBuyerReviewNotice3')}}
          </div> -->
          <!-- <div class="service-notice">
            {{$t('creditBuyerPayNotice1')}}
            <span>
              {{$t('creditBuyerPayNotice2')}}
            </span>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";

export default {
    metaInfo: {
      title: 'Home',
    },
  data() {
    return {
      isShow: false
    };
  },
  components: {
    BaseTitle
  }
};
</script>

<style
    DownloadModal lang="scss" scoped>
@import "./src/assets/sass/main.scss";

#credit-service {
  position: relative;
  .api-service-wrapper {
    padding: 50px 60px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .service-container {
    width: 320px;
    &:first-child {
      margin-right: 50px;
      @media screen and (max-width: 768px) {
         margin-right: 0px;
         margin-bottom: 50px;
      }
    }
    .service-title {
      font-size: 18px;
      margin-bottom: 16px;
    }
    .service-text {
      line-height: 26px;
    }
    .service-notice {
      color: $subTitle;
      font-size: 13px;
      margin: 16px 0;
      span {
        color:$gradientdeepGreen;
      }
    }
    .service-file {
      cursor: pointer;
      color: $mainBlue;
      transition: .3s ease-in-out;
      margin-bottom: 12px;
      &:hover {
      color: $deepBlue;
      }
    }
  }
}

/deep/ {
  .about-tabs {
    display: none;
  }
}

#download-modal{
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;
  background: white;
  z-index: 997;
  border-radius: 12px;
  box-shadow: 0 5px 10px $shadowBlue;
}

.download-modal {
  .modal-header {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .modal-text {
    line-height: 22px;
  }

  .modal-closed {
    cursor: pointer;
    height: 16px;
    width: 16px;
    background-image:url("../assets/imgs/close.svg") ;
    background-size: cover;
  }

  .modal-subtitle {
    font-size: 16px;
    margin-bottom: 16px;
    font-weight: bold;
    color: $textBlue;
  }

  .download-btns {
    display: flex;
    align-items: flex-end;
     margin: 20px 0;
     padding-bottom: 20px;
     border-bottom: 1px solid $lightBlue;
     .qrcode {
        height: 80px;
      }
    img {
      height: 36px;
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}

.modal-enter-active {
  transform: translateY(-50px);
  transition: all .3s ease;
}

.modal-leave-active {
 transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.modal-enter, .modal-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

a {
  color: $mainBlue;
  &:hover {
    color: $deepBlue;
  }
}

/deep/ {
  #base-title {
    .top-banner {
    .banner-photo {
      background-image: url('../assets/imgs/page-banner/credit.jpg');
      }
    }
  }
}


</style>
