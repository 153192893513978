<template>
  <div id="our-history">
    <BaseTitle pageTitle="Our History" :pageSubtitle="$t('historyPageSubtitle')"></BaseTitle>
    <div class="history-us-wrapper">
      <div class="container-left">
        <div class="blue-deco"></div>
        <div class="history-img"></div>
      </div>
      <div class="container-right">
      <div class="history-container">
        <div class="history-list">
          <div class="history-item">
            <div class="history-year">2013</div>
            <div class="history-content">{{$t('historyContent2013_01')}}</div>
          </div>
           <div class="history-item">
            <div class="history-year">2014</div>
            <div class="history-content">{{$t('historyContent2014_01')}}</div>
          </div>
           <div class="history-item">
            <div class="history-year">2015</div>
            <div class="history-content">{{$t('historyContent2015_01')}}</div>
          </div>
          <div class="history-item">
            <div class="history-year">2016</div>
            <div class="history-content-wrapper">
              <div class="history-content">{{$t('historyContent2016_01')}}</div>
              <div class="history-content">{{$t('historyContent2016_02')}}</div>
            </div>
          </div>
          <div class="history-item">
            <div class="history-year">2017</div>
            <div class="history-content">{{$t('historyContent2017_01')}}</div>
          </div>
          <div class="history-item">
            <div class="history-year">2018</div>
            <div class="history-content-wrapper">
              <div class="history-content">{{$t('historyContent2018_01')}}</div>
              <div class="history-content">{{$t('historyContent2018_02')}}</div>
              <div class="history-content">{{$t('historyContent2018_03')}}</div>
            </div>
          </div>
          <div class="history-item">
            <div class="history-year">2019</div>
            <div class="history-content-wrapper">
              <div class="history-content">{{$t('historyContent2019_01')}}</div>
              <div class="award-content">{{$t('historyContent2019_02')}}</div>
            </div>
          </div>
          <div class="history-item">
            <div class="history-year">2020</div>
            <div class="history-content-wrapper">
              <div class="history-content">{{$t('historyContent2020_01')}}</div>
              <div class="history-content">{{$t('historyContent2020_02')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="invest-wrapper">
        <div class="invest-container">
          <div class="invest-item">
            <div class="title">{{$t('investSeedTitle')}}</div>
            <div class="content">
              {{$t('investSeedContent')}}
            </div>
          </div>
          <div class="invest-item">
            <div class="title">{{$t('investPreATitle')}}</div>
            <div class="content">
              {{$t('investPreAContent')}}
            </div>
          </div>
          <div class="invest-item">
            <div class="title">{{$t('investATitle')}}</div>
            <div class="content">
              {{$t('investAContent')}}
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
  </div>
</template>

<script>
import BaseTitle from "@/components/BaseTitle";

export default {
    metaInfo: {
      title: 'Home',
    },
  components: {
    BaseTitle
  }
  }
</script>

<style lang="scss" scoped>
@import "./src/assets/sass/main.scss";

.history-us-wrapper {
  padding: 50px 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media screen and (max-width: 900px) {
    padding: 50px;
  }
  @media screen and (max-width: 720px) {
    padding: 50px 30px;
    flex-direction: column-reverse;
  }
}

.history-container,
.invest-container {
  width: 390px;
  margin-bottom: 80px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
  @media screen and (max-width: 720px) {
    width: 100%;
    margin-bottom: 50px;
  }
}

.invest-item {
  font-size: 14px;
  color: $textBlack;
  margin-bottom: 50px;

  .title {
    font-weight: 500;
  }

  .content {
    margin-top: 12px;
    line-height: 23px;
  }
}

.container-left {
  position: relative;
  margin-right: 100px;
  margin-top: 500px;
  @media screen and (max-width: 720px) {
    margin-top: 0px;
  }
  .blue-deco {
    position: absolute;
    top: 0px;
    right: -30px;
    z-index: -1;
    width: 239px;
    height: 245px;
    background: $lightBlue;
  }

  .history-img {
    width: 285px;
    height: 370px;
    background-image: url('../assets/imgs/page-banner/history-invest.jpg');
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 720px) {
      width: 240px;
      height: 300px;
    }
  }
}

.history-list {
  margin-top: 30px;

  .history-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 12px;
    line-height: 24px;

    .history-year {
      margin-right: 22px;
    }

    .history-content-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}

/deep/ {
  #base-title {
    .top-banner {
    .banner-photo {
      background-position: center;
      background-image: url('../assets/imgs/page-banner/history.jpg');
      }
    }
  }
}

</style>
